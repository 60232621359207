import { ICaseStudy, ICategoryItem } from "../types";
import withPasswordProtection from "../components/WithPasswordProtection";
import { getCateogryItems, valueProps } from "../components/caseStudies/categories";
import { Link } from "react-router-dom";
import { useEffect } from "react";

interface IMasonryItem {
    caseStudy: ICaseStudy
}


// masonry item with random colors and hegiht optinoally from props

const MasonryItem = ({ caseStudy }: IMasonryItem) => {

    return (

        <div className={`w-full h-full bg-white shadow-xl shadow-slate-30 rounded-lg cursor-pointer flex flex-col`}>
            <div className="w-full">
                {caseStudy.heroImg && <img src={caseStudy.heroImg} alt={caseStudy.headline} className="w-full object-cover aspect-video rounded-t-lg " />}
            </div>
            <div className="w-full grow p-4 flex flex-col space-y-4 my-2 justify-between">
                <div>
                    <h3 className="text-base font-semibold leading-tight">{caseStudy.headline}</h3>
                    <p className="text-sm mt-2">{caseStudy.subheadline}</p>
                </div>

                <button className=" text-sm text-accent-dark inline-flex items-center">
                    Learn more
                    <span className="ml-2">→</span>
                </button>
            </div>
        </div>

    );
}

interface ICategoryPageProps {
    category: string | undefined
}

const CategoryPage = ({ category }: ICategoryPageProps) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let categoryValueProp = valueProps.find(vp => vp.id === category);
    let categoryTitle = categoryValueProp?.headline || '';
    let categoryItems: ICategoryItem[] = category ? getCateogryItems(category) : []

    return (
        (!categoryValueProp) ? <div>Oops! We couldn't find the right category</div> : <div className="p-4 w-full container">
            <div className="pb-4 md:py-20 md:flex md:flex-col md:items-center">
                <h2 className="text-2xl md:text-4xl uppercase">{categoryTitle}</h2>
                <div className="prose mt-4 md:text-center !text-slate-950">
                    {categoryValueProp?.subheadline && <p className="text-base md:text-lg">{categoryValueProp.subheadline}</p>}
                </div>
            </div>

            <div className="flex flex-col items-center">
                {
                    (!categoryItems || categoryItems?.length == 0) ? <div className="text-center text-lg italic">Under construction.</div>
                        :
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6">
                            {categoryItems?.map((item, index) => (
                                <Link to={`/case-studies/${category}/${item.id}`} key={index} className={`${categoryItems.length < 2 ? 'lg:col-start-2' : ''}`}>
                                    <MasonryItem
                                        caseStudy={item.caseStudy}
                                        
                                    />
                                </Link>
                            ))}
                        </div>
                }
            </div>

            {/* {
                // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-masonry-layout/react-masonry-layout-tests.tsx
            }
            <ResponsiveMasonry
                className="w-full"
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
                <Masonry>
                    {categoryItems?.map((item, index) => (
                        <MasonryItem
                            key={index}
                            caseStudy={item.caseStudy}
                            setCaseStudy={setCaseStudy}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry> */}

        </div>
    );
}

export default withPasswordProtection(CategoryPage);