import { ICategoryItem, IValueProp } from "../../types";
import { caseStudyTelekom } from "./servicedesign/CaseStudyTelekom";
import { houseOfMusic } from "./servicedesign/Fujimoto";
import { rogersMoves } from "./servicedesign/RogersMoves";
import { rogersTechSupport } from "./servicedesign/RogersTechSupport";
import { scotiaCreditCards } from "./servicedesign/ScotiaCreditCards";
import { scotiaOlderAdults } from "./servicedesign/ScotiaOlderAdults";
import { ideateLive } from "./ux/ideateLive";
import { rogersUXR } from "./ux/rogersuxr";

export const valueProps : IValueProp[] = [
    {
        headline: "Journey & Service Design",
        subheadline: "Crafting seamless experiences by connecting insights, strategy, and design across every touchpoint.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/workshop.JPG",
        id: "servicedesign"
    },
    {
        headline: "UX Design & Research",
        subheadline: "Applying user research and design to create seamless, engaging, and effective digital experiences.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/ux.png",
        id: "ux"
    },
    {
        headline: "Generative AI & Tech",
        subheadline: "Combining tech expertise with strategic foresight and rapid prototyping to craft future-ready experiences.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/tech.jpg",
        id: "tech"
    },
]


export const caseStudies : ICategoryItem[]  = [
    {
        category: 'servicedesign',
        id: 'caseStudyTelekom',
        caseStudy: caseStudyTelekom,
    },
    {
        category: 'servicedesign',
        id: 'rogersTechSupport',
        caseStudy: rogersTechSupport,
    },
    {
        category: 'servicedesign',
        id: 'rogersMoves',
        caseStudy: rogersMoves,
    },
    {
        category: 'servicedesign',
        id: 'scotiaOlderAdults',
        caseStudy: scotiaOlderAdults,
    },
    {
        category: 'servicedesign',
        id: 'scotiaCreditCards',
        caseStudy: scotiaCreditCards
    },
    {
        category: 'servicedesign',
        id: 'houseOfMusic',
        caseStudy: houseOfMusic
    },
    {
        category: 'ux',
        id: 'ideateLive',
        caseStudy: ideateLive
    },
    {
        category: 'ux',
        id: 'rogersUXR',
        caseStudy: rogersUXR
    }
]

export const getCateogryItems = (category: string) => {
    // look up the category in caseStudies and return the items
    return caseStudies.filter(cs => cs.category === category)
}