
import { ICaseStudy } from "../../../types";


export const houseOfMusic: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/houseofmusic/hero.jpg`,
    headline: 'Public Museum Service Design',
subheadline: 'Validated and refined visitor experiences for the new House of Music Museum to align with the architectural vision and improve visitor satisfaction.',
overview: 'Collaborated with the Hungarian government to ensure the planned visitor experiences for the Fujimoto-designed House of Music Museum met the desired standards. Conducted research, developed personas, and mapped detailed service blueprints to align visitor journeys with architectural and operational goals. Supervised two junior designers, resulting in key adjustments to the wayfinding system and improved visitor flow management. The museum has since gained international recognition as one of CNN’s top ten most anticipated buildings of 2021.',
goals: [
    {
        title: 'Visitor Experience',
        values: [
            'Ensure the planned visitor experience aligns with the museum’s architectural vision and operational goals',
            'Identify and address potential experience gaps to enhance visitor satisfaction'
        ]
    },
    {
        title: 'Operational Alignment',
        values: [
            'Link visitor experiences with underlying resources, processes, and touchpoints to optimize museum operations',
            'Facilitate collaboration between architects, planners, and operational teams to ensure consistent implementation'
        ]
    }
],
outcomes: {
    title: 'Outcomes',
    values: [
        'Improved wayfinding system to support better visitor navigation',
        'Optimized visitor flow based on spatial and temporal constraints identified in the research',
        'Enhanced alignment between the architectural design and intended visitor experiences'
    ]
},
callouts: [
    {
        label: 'My role',
        sections: [
            {
                title: 'Senior Service Designer',
                values: [
                    'Project supervision and mentorship for junior designers',
                    'Qualitative research (interviews, observational studies)',
                    'Persona development',
                    'Experience mapping',
                    'Service blueprinting',
                    'Experience gap analysis',
                    'Collaboration with architects and operational teams',
                    'Stakeholder presentations and recommendations'
                ]
            }
        ]
    },
    {
        label: 'Methods',
        sections: [
            {
                title: 'Research Methods',
                values: [
                    'Qualitative research (interviews and observational studies)',
                    'Review of architectural blueprints and use cases',
                    'Persona development and experience mapping'
                ]
            },
            {
                title: 'Design Workshops',
                values: [
                    'Service blueprinting workshops',
                    'Experience gap analysis workshops',
                    'Stakeholder alignment workshops'
                ]
            },
            {
                title: 'Deliverables',
                values: [
                    'Visitor Personas',
                    'Experience Maps',
                    'Service Blueprints',
                    'Experience Gap Analysis Report',
                    'Final Recommendations Presentation'
                ]
            }
        ]
    },
    {
        label: 'Team',
        sections: [
            {
                title: 'Project Team',
                values: [
                    'Senior Service Designer',
                    '2 Junior Service Designers'
                ]
            },
            {
                title: 'Stakeholders',
                values: [
                    'Museum Planners',
                    'Architects',
                    'Operational Teams',
                    'Hungarian Government Representatives'
                ]
            }
        ]
    }
],
    processMD:`
## Design Process

1. Oversaw and guided two junior Service Designers throughout the project, providing mentorship and ensuring high-quality deliverables.
2. Analyzed architectural blueprints and intended use cases to identify spatial and temporal constraints affecting visitor flow and overall experience.
1. Planned and executed qualitative research, including interviews and observational studies, to develop detailed personas representing various visitor segments and their needs.
1. Created personas and experience maps to visualize key visitor journeys, aligning them with the architectural vision and operational goals.
1. Mapped detailed service blueprints to link the desired visitor experiences with underlying museum resources, processes, and touchpoints.
1. Analyzed experience maps and blueprints to identify potential experience gaps and opportunities to enhance visitor satisfaction.
1. Conducted workshops with museum planners, architects, and operational teams to align on the proposed service blueprints and prioritize recommended changes.
1. Documented and presented findings to the project’s governing body, influencing adjustments such as modifications to the wayfinding system and visitor flow management.
` 
};
