
const getLogos = () => {
    var logoPrefix = process.env.PUBLIC_URL + "/logos/"
    var logos = [
      "unhcr",
      "MIT",
      "logmein",
      "citrix",
      "lego",
      "tmobile",
      "scotiabank",
      "rogers",
      "dentsu",
      //"isobar",
      //"erste"
    ]
    var logoSuffix = ".png"
    return logos.map(logo => logoPrefix + logo + logoSuffix)
  }

export const Logos = () => {
    return (
        <div className='flex flex-wrap gap-4 md:gap-6 opacity-60  justify-center'>
          {
            getLogos().map(logo => <img src={logo} alt="Company Logo" className="max-h-4 md:max-h-5" />)
          }
        </div>
    )
}