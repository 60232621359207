import { ICaseStudy } from "../../../types";

export const rogersUXR: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + '/casestudies/rogersuxr/media.png',
    headline: 'Transforming UX Research into a Strategic Partner for Agile Product Teams',
    subheadline: 'Transformed and tripled a participative UX Research practice, empowering cross-functional product teams and embedding research into the standard way of work.',
    overview: `
- Transformed the UX research practice at Rogers from a siloed support function into a strategic partner embedded within agile product teams. 
- Rebuilt the research team after years of attrition, tripling its size by demonstrating business value and securing new budget streams. 
- Introduced a participative research approach that empowered product teams to engage directly in research activities, leading to higher visibility and faster adoption of insights. 
- Leveraged Research Operations to standardize processes, improve efficiency, and democratize usability- and comprehension testing across teams. 
- These efforts resulted in increased stakeholder buy-in, stronger team engagement, and a more unified, research-driven culture across the organization.`,
    goals: [
        {
            title: 'Team Engagement',
            values: [
                'Rebuild the research team and improve engagement and satisfaction'
            ]
        },
        {
            title: 'Support Agile Teams',
            values: [
                'Introduce participative research to leverage diverse perspectives',
                'Unlock new budget streams by demonstrating research impact',
                'Secure a seat in PI planning to advocate for resources'
            ]
        },
        {
            title: 'Research Operations',
            values: [
                'Implement remote research tools',
                'Democratize testing with tools and training for UX and Content teams'
            ]
        },
        {
            title: 'Visibility and Alignment',
            values: [
                'Increase visibility and utilization of research across the organization',
                'Align processes with other insights teams for a unified approach'
            ]
        }
    ],
    outcomes: {
        title: 'Outcomes',
        values: [
            'Tripled the research team size, while maintaining 100% utilization, by partnering closely with Product',
            'Increased research visibility and stakeholder buy-in through participative methods',
            'Improved team engagement and satisfaction, as reflected in team engagement surveys'
        ]
    },
    callouts: [
        {
            label: 'My Role',
            sections: [
                {
                    title: 'Manager, UX Research',
                    values: ['Led the transformation of the UX Research practice at Rogers Communications']
                }
            ]
        },
        {
            label: 'Methods',
            sections: [
                {
                    title: 'Discovery & Context Building',
                    values: [
                        'User Interviews',
                        'Stakeholder Interviews',
                        'Contextual Inquiry',
                        'Context Labs',
                        'Persona & Archetype Development',
                        'Landscape/Competitive Analysis',
                    ]
                },
                {
                    title: 'Exploring Mental Models',
                    values: [
                        'Card Sorting',
                        'Tree Testing',
                        'Participatory Design / Co-Creation Workshops'
                    ]
                },
                {
                    title: 'Evaluating Concepts & Prototypes',
                    values: [
                        'Heuristic Evaluation',
                        'Behavioral Data Analysis',
                        'Moderated Usability Testing',
                        'Unmoderated Usability Testing',

                    ]
                },
                {
                    title: 'Post-Launch Research & Continuous Improvement',
                    values: [
                        'Surveys (CSAT)',
                        'Feedback Analysis (call logs, surveys)',
                        'Usability Benchmarking'
                    ]
                }
            ]
        }
    ],
    processMD: `
# Approach

 <figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/rogersuxr/rogers.png`}"/>
  <figcaption>Illustration of various Rogers digtial porterties my UX Research team supported.</figcaption>
</figure>

## Partnering with product using a participative approach

- **From siloed to integrated: creating product-centric and participative research**  
  Embedded researchers into key product lines like Wireless, B2B, and Media to build domain expertise and deepen relationships. Instead of just presenting findings, they used lightweight FigJam/Mural-based deliverables to involve product teams in research, inviting product owners, designers, and developers to observe live sessions and shape research questions. This open approach resulted in deeper customer understanding, better alignment, diverse perspectives (e.g., content designers picking up on language), and more buy-in as teams developed a sense of ownership.

- **Securing a seat at the PI planning table**  
  Volunteered to lead a redesign of the digital delivery process, using this role to advocate for the strategic value of UX research. This involvement secured a formal seat for UX research in the PI planning process, ensuring that research had the time and resources needed for early-stage involvement in key projects.

- **Unlocking new budget streams by building trust and showing value**  
  Collaborated closely with product owners and senior product leaders, showcasing how research directly impacted product and customer experience KPIs. Shared live research sessions and reports in Product Community of Practice meetings, PO meetings, and Director open hours. This approach led to new budget approvals, tripling the research team size and expanding operations.

<figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/rogersuxr/fido.png`}"/>
  <figcaption>Illustration of various Rogers digtial porterties my UX Research team supported.</figcaption>
</figure>


## Democratizing research and scaling usability testing

- **Enabling UX Designers to run usability tests:** Provided the necessary tools and step-by-step guides for UX and content teams to independently conduct usability tests. Offered live support sessions to help them gain confidence in running these activities.

- **Usability.com procurement & implementation:** Procured and introduced UserTesting.com, a remote research tool that enabled teams to conduct both moderated and unmoderated studies. This streamlined the process for gathering feedback and allowed more flexibility in how teams conducted research.

- **Standardizing processes & guidelines:** Developed clear research guidelines outlining when UX and content designers should conduct testing independently and when to request help from the core research team. This allowed teams to understand how to run their own studies without sacrificing research quality.

- **Open research repository:** Created a shared research repository organized by product lines and common user needs, making it easy for teams to find relevant insights. This reduced duplication of effort and ensured that knowledge was accessible across the organization.

- **Scaling generative research with Context Labs:** Scheduled context labs for teams to run their own deep-dive research sessions, especially for hard-to-reach groups like agents. This allowed teams to explore broader research questions without needing senior researchers to lead every session.


<figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/rogersuxr/media.png`}"/>
  <figcaption>Illustration of various Rogers digtial porterties my UX Research team supported.</figcaption>
</figure>

## Measuring success: KPIs and metrics

My approach to measuring research success was centered around aligning UX research metrics with broader product KPIs. I believe that to truly demonstrate the value of research, we needed to show its impact on business goals and product performance.

- **Product KPIs alignment:** Focused on linking research insights to core product KPIs such as feature adoption, conversion rates, customer retention, and call deflection. We achieved this by using participative research planning, where product teams helped shape research questions and goals, making it easier to connect UX research metrics with product KPIs.

- **UX research-specific metrics:** Tracked traditional research metrics like task completion rates, success rates, and usability issue resolution times to measure usability and design effectiveness.

- **CSAT surveys:** Used Customer Satisfaction (CSAT) surveys integrated and aligned with other tracked channels to gauge the impact of UX changes on customer sentiment and satisfaction, helping us identify areas for improvement and validate research outcomes.


## Building bridges with other insights teams

- **Partnerships with Support Innovation & Agent Tools Teams**  
  Worked together to access transaction data and agent transcripts, enabling deeper insights through direct interviews and co-creation sessions with agents.

- **Collaborations with Data Analytics & Personalization Teams**  
  Aligned on data sources, combining quantitative analytics and A/B testing results to support research findings and strengthen insights.

- **Integrating with Consumer Insights**  
  Shared research repositories and clarified each team’s role, helping internal clients understand when to use UX research versus Consumer Insights for their needs.

- **Service Design process alignment**  
Aligned the Service Design process to bring research into projects earlier. This enabled more generative research, allowing the team to explore broader user needs and strategic questions. It also improved job satisfaction by giving researchers more engaging and meaningful work.


The shift to a participative research model transformed the way product teams engaged with insights. By embedding researchers, aligning with agile processes, and empowering teams to take part, we achieved deeper customer understanding, stronger alignment, and more ownership as evidenced by the tripled research team size.`
};
