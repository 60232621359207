
import { ICaseStudy } from "../../../types";


export const rogersMoves: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/rogersmoves/hero.jpg`,
    headline: 'Move Experience Service Design',
    subheadline: 'Redesigned the Internet moving journey, reducing support calls and improving transparency with digital-first solutions.',
    overview: 'Led the data-driven redesign of the moving Internet services journey, transitioning from a fragmented, call-heavy support model to a streamlined, digital-first experience. Integrated real-time tracking, automated notifications, and data-informed right-sizing offers to increase transparency, reduce support calls, and optimize both customer and staff experiences.',
    goals: [
        {
            title: 'Revenue Increase',
            values: [
                'Drive revenue growth through personalized, data-driven right-sizing offers during the move process',
                'Leverage customer insights to recommend optimized service packages based on individual needs and scenarios'
            ]
        },
        {
            title: 'Cost Reduction',
            values: [
                'Reduce support costs by transitioning from a fragmented call-heavy support model to a digital-first approach',
                'Lower operational expenses through automated notifications and real-time tracking'
            ]
        },
        {
            title: 'Customer Experience',
            values: [
                'Enhance transparency during the moving process with real-time updates and status tracking',
                'Minimize customer confusion by providing a streamlined, digital-first journey'
            ]
        },
        {
            title: 'Digital Adoption',
            values: [
                'Encourage digital-first interactions for move-related support scenarios',
                'Increase the use of digital tools such as the "Moves Online Portal" and "Virtual Assistant"'
            ]
        }
    ],
    outcomes: {
        title: 'Outcomes',
        values: [
            'Increased revenue through personalized right-sizing offers and optimized service packages',
            'Reduced support calls through improved digital self-service options',
            'Higher customer satisfaction through a more transparent and streamlined moving experience',
            'Improved collaboration across teams by aligning on a unified service blueprint'
        ]
    }
    ,
    callouts: [
        {
            label: 'My role',
            sections: [
                {
                    title: 'Lead Service Designer',
                    values: [
                        'Cross-channel data analysis',
                        'Qualitative research and field studies',
                        'Workshop facilitation',
                        'Service blueprinting',
                        'Persona development',
                        'Experience mapping',
                        'Concept development',
                        'Collaboration with agile product teams'
                    ]
                }
            ]
        },
        {
            label: 'Methods',
            sections: [
                {
                    title: 'Research Methods',
                    values: [
                        'Cross-channel service data analysis',
                        'Market trend analysis',
                        'Qualitative research (field studies, interviews)',
                        'Co-design workshops with call center agents and field technicians'
                    ]
                },
                {
                    title: 'Design Workshops',
                    values: [
                        'Current-state journey mapping',
                        'Service blueprinting (current- and future-state)',
                        'Experience gap analysis',
                        'Ideation and concepting',
                        'Future-state journey mapping'
                    ]
                },
                {
                    title: 'Deliverables',
                    values: [
                        'Cross-Channel Support Data Analysis Report',
                        'User personas',
                        'User journey maps',
                        'Experience maps',
                        'Service blueprints (current- and future-state)',
                        'Experience Gap Analysis Report'
                    ]
                }
            ]
        },
        {
            label: 'Team',
            sections: [
                {
                    title: 'Project Team',
                    values: [
                        'Service Designers',
                        'UX Designers',
                        'Product Owners'
                    ]
                },
                {
                    title: 'Stakeholders',
                    values: [
                        'Call Center Agents',
                        'Field Technicians',
                        'Customer Experience',
                        'Marketing',
                        'Consumer Insights'
                    ]
                }
            ]
        }
    ],
    processMD:`
## Design Process

1. Analyzed cross-channel service data (web, call center, field service) to identify key drivers of support calls during a move. Identified high-impact pain points and customer right-sizing opportunities.
2. Analyzed reports from the Consumer Insights team covering market trends and behavioral data to build evidence-based personas, service scenarios (e.g., new build, rental, older detached homes), and experience maps.
3. Facilitated co-design workshops with call-center agents and field technicicans to map their mental models of the current user journey. Gathered key customer pain points observed in their day-to-day interactions, captured hypotheses on call drivers, and documented their own pain points. Engaged agents in ideating an ideal experience for both customers and staff.
4. Facilitated current-state Service Blueprinting workshops using a base-journey approach to capture multiple service scenarios. Mapped underlying capabilities and integrated consumer insights from prior report analysis along with frontline staff inputs from co-creation workshops, identifying gaps and opportunities.
5. Facilitated ideation and future-state blueprinting workshops to develop new service concepts, introducing key touchpoints such as the “Moves Online Portal” and “Virtual Assistant” to create a seamless digital experience.
6. Contributed to change management by partnering with Digital and Customer Experience teams to implement new digital touchpoints across all channels. Collaborated with Agile teams to translate service blueprints into epics and user stories.

` 
};
