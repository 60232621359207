export const BlockHeader = ({ headline, subheadline }: { headline?: string, subheadline?: string }) => {
    return (
      <div className='flex flex-col space-y-4 items-center pb-4 md:pb-8'>
        {headline && <p className='main-contaier-title'>
          {headline}
        </p>}
        {subheadline && <p className='max-w-prose text-center text-sm md:text-base'>
          {subheadline}
        </p>}
      </div>
    )
  }