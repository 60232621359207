
import { ICaseStudy } from "../../../types";


export const caseStudyTelekom: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/telekombilling/hero.png`,
    headline: 'Transforming T-Mobile’s Billing Experience Through Service Design',
    subheadline: 'A deep dive into optimizing billing-related costs and enhancing customer experience using participative research and design thinking.',
    overview: 'T-Mobile approached our digital agency to solve a persistent issue of reducing billing service costs. As a Lead Service Designer, I guided a cross-functional team from various departments through participative research and workshops to identify key cost levers and redesign billing products and journeys. The project resulted in a 20% reduction in billing costs and a 10% improvement in customer experience.',
    goals: [
        {
            title: 'Cost Reduction',
            values: [
                'Reduce costs of in-store payments',
                'Lower billing-related call costs',
                'Decrease costs associated with paper bill representation',
            ]
        },
        {
            title: 'Customer Experience',
            values: [
                'Improve customer experience for bill payment',
                'Enhance bill completion experience',
            ]
        },
        {
            title: 'Digital Adoption',
            values: [
                'Increase online payment adoption',
                'Boost digital bill adoption',
                'Increase customers opting for authorized payments and bank transfers',
            ]
        }
    ],
    outcomes: {
        title: 'Outcomes',
        values: [
            '20% reduction in billing-related costs',
            '10% increase in digital adoption',
            'Maintained customer experience levels despite the forced migration from in-store payments to digital options',
        ]
    }
    ,
    callouts: [
        {
            label: `My role`,
            sections: [
                {
                    title: 'Lead Service Designer (Consultant)',
                    values: [
                        'Project proposal',
                        'Client meetings',
                        'Sales process',
                        'Research sessions',
                        'Workshop facilitation',
                        'Synthesis',
                        'Reporting',
                        'Client presentations'
                    ]
                }
            ]
        },
        {
            label: 'Methods',
            sections: [
                {
                    title: 'Research Methods',
                    values: [
                        'Participative research',
                        'Home visits',
                        'Contextual inquiries',
                        'Digital usability sessions',
                        'Store visits',
                        'Call center agent interviews',
                    ]
                },
                {
                    title: 'Design Workshops',
                    values: [
                        'Current-state mapping',
                        'Problem framing',
                        'Research synthesis',
                        'Ideation and Concepting',
                        'Target-state journey mapping',
                        'Service blueprinting',
                        'Prototyping',
                        'Measurement system planning',
                    ]
                },
                {
                    title: 'Deliverables',
                    values: [
                        'Service blueprints',
                        'Experience maps',
                        'Customer journey maps',
                        'Business model canvases',
                        'Value proposition canvases',
                        'Digital prototypes',
                        'Prototype agent scripts',
                        'Prototype store experiences',
                    ]
                }
            ]

        },
        {
            label: 'Team',
            sections: [
                {
                    title: 'Agency team',
                    values: [
                        'Service Designers',
                        'UX Designers',
                        'Content designers',
                    ]
                },
                {
                    title: 'Client team',
                    values: [
                        'Digital Transformation Office',
                        'Billing Department',
                        'Customer Experience Department',
                        'Digital Experience Department',
                        'Store Operations',
                        'Call Center Operations',
                    ]
                }
            ]
        }
    ],
    processMD:`
# Design Process

## 1. Problem Framing

After receiving the initial brief, which focused on increasing digital bill adoption, we dug deeper to understand the underlying cost drivers. Although the brief targeted bill representation, our analysis of transaction cost data revealed that payment methods were a far more significant factor in billing-related costs. Based on this insight, we proposed assembling a cross-functional team with representatives from all departments involved in billing—such as back office, stores, digital, call centers, customer experience, and other enablement teams. This team of fifteen mid-level managers and decision-makers participated in a fully participative process, conducting research under my coaching and collaboratively designing and testing target state solutions.



## 2. Current-State Mapping

<figure>
  <video src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/blueprint.mp4`}" loop muted alt="Video of a Service Blueprinting workshop.">
  </video>
  <figcaption><b>Current-state Service Blueprinting.</b> Our initial inside-out view on the experience, touchpoints and the underlying capabilities. The focus points and assumptions we identified guided research planning. <a href="https://www.youtube.com/watch?v=y_Vlio5fMXk")>Source: https://www.youtube.com/watch?v=y_Vlio5fMXk</a></figcaption>
</figure>

In collaboration with the cross-functional group, we conducted workshops to map out every current state bill payment journey across various channels, including in-store, call centers, digital channels, and automated methods like authorized bank transfers. During the mapping process, we also identified backend processes through service blueprinting, highlighting the steps that enable these journeys. This exercise allowed us to pinpoint key assumed pain points from the customer's perspective and formulate critical questions to investigate during the research phase. Additionally, we identified different customer segments based on their preferred payment methods and prioritized the key segments that could drive the most significant impact.

## 3. Participative Research

We divided the cross-functional group into teams of three or four, assigning them to different research activities based on specific customer segments and their preferred payment methods.

All research was conducted with the active participation of the cross-functional project team with the Service Design team's coaching.

<figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/telekom-home-visit.png`}" alt="Wall with customer archetypes and adoption paths" class="aspect-video"/>
  <!--<figcaption><b>Home visits to understand at-home bill payment behaviour.</b> Conducted together with representatives of the cross-functional team. Photos publicly released as part of the case-study video. <a href="https://www.youtube.com/watch?v=y_Vlio5fMXk")>Source: https://www.youtube.com/watch?v=y_Vlio5fMXk</a></figcaption>-->
</figure>

For those who primarily paid through bank transfers or pre-authorized payments, we conducted home visits. 


<figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/digital.JPG`}"/>
  <!--<figcaption><b>Digital contextual inquiry.</b></figcaption>-->
</figure>
 
For customers who paid online or through the mobile app, we carried out digital contextual inquiries, to observe their payment behaviors, combined with follow-up interviews.


 <figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/storevisit.JPG`}"/>
  <!--<figcaption><b>Store visit and shadowing sales representatives.</b> Conducted together with the cross-functional team and UX Designers who were involved later in designing future-state digital services.</figcaption>-->
</figure>
 
For customers who preferred in-store payments, we conducted store visits and guerrilla interviews on-site. 

 <figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/researchsynth.png`}"/>
  <figcaption>Research synthesis wall for one business segment: behavioural archetypes (red), bill payment maturity pathways (blue), financial impact calculations (yellow), prjoect board decisions (green).</figcaption>
</figure>

With the conclusion of all research sessions, we organized a comprehensive research synthesis workshop, where the team consolidated insights from the various research sessions and mapped out behavioural archetypes and experience maps to visualize customer journeys. 

We also identified design principles, and corresponsing metrics, that define what makes a bill payment service great for customers to guide the design of the final solutions.

Following the synthesis, we identified the top five opportunities that could make the most significant impact. These opportunities were then presented to a Director Plus board, which included directors of digital stores, call centers, and digital transformation strategy, who reviewed and approved the focus areas for further design.

## 4. Ideation and Concepting

After the director plus board approved the top five opportunity areas, we held ideation sessions with the cross-functional group. 

<figure>
  <video src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/pitching.mp4`}" loop muted alt="Video of a Service Blueprinting workshop.">
  </video>
  <figcaption>Concept pitching. Top ideas from ideation were developed into napkin pitches. Concept teams were formed around which pitch they liked the most.</figcaption>
</figure>

Participants who were passionate about specific ideas formed teams to flesh them out into low-fidelity concepts, using tools such as storyboards and napkin pitch canvases. These concepts were pitched within the group, and project teams were formed based on interest and alignment with the pitches.

We introduced UX designers, content designers, and control functions into the cross-functional teams to ensure early consideration of privacy and fraud risks. Involving these control functions from the beginning helped secure regulatory approval and fostered a sense of ownership over the solution. 

 <figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/concepting.png`}"/>
  <figcaption>Concepting artefacts: Business Model Canvas (green), future-state experience map (blue), analogue services (yellow).</figcaption>
</figure>


With the teams established, they further developed the concepts into future-state journey maps and business model canvases, together with an estimate of each solution's potential financial impact. The teams then presented these refined concepts to the project board for feedback. 

To prepare for prototyping and testing, teams identified key assumptions underlying their concepts and created prototypes to test against these assumptions.

## 5. Prototyping, and Testing

### Digital Experiences

#### Paying without a password

The team began by creating paper prototypes during workshops. The UX designer then iteratively increased the prototype's fidelity from low to high, conducting usability tests with follow-up interviews between each iteration. This iterative process allowed for continuous improvement based on customer feedback, leading to a production-ready solution that was eventually shipped.

### In-Store Experiences

#### Moving cash payments to a third party

The idea to move cash payments to a newspaper kiosk network began as a concept on a Post-it note, which was then developed into a business model canvas to assess potential financial savings. This idea was mapped into a customer journey and service blueprint, identifying key partners and assumptions that required validation. We reached out to experts, including the News Kiosk Network team, and involved them through interviews. Together, we co-created a prototype for the repayment solution that evolved into an official inter-company partnership.

The solution was piloted in five cities, and after implementation, we conducted field visits and interviews with customers, kiosk representatives, and salespeople to evaluate both employee and customer experiences. We also tracked cost savings and experience KPIs quantitatively throughout the pilot, actively participating in the data gathering and analysis process. The results were highly successful, proving the viability of this innovative approach.

### At-Home Experiences

We identified a segment of customers who paid their bills through manual bank transfers and recognized their potential openness to setting up pre-authorized payments if they saw savings benefits. To reach this group, who typically paid through third-party bank websites, we decided to test the effectiveness of text message outreach. Content designers from our agency joined the cross-functional team and collaboratively drafted two versions of potential text message copies. The content designer then refined these into several well-rounded versions. We partnered with T-Mobile's communications team to set up a test process to determine which message copy was most effective in converting customers to pre-authorized payments. Based on the results, the most successful version was implemented, creating a new customer touchpoint.

## 6. Going Live and Measuring Results

During the implementation phase, it became clear that, surprisingly, the digital solutions took the longest to ship—over a year. By that time, the tracking and measurement of these digital solutions were managed by T-Mobile's customer experience and digital experience teams.

<figure>
  <img src="${process.env.PUBLIC_URL + `/casestudies/telekombilling/postresearch.JPG`}" alt="Wall with customer archetypes and adoption paths" />
  <figcaption><b>Research wall for synthesizing contextual inquiry of the live partner payment service.</b></figcaption>
</figure>

For the partnership solution with the newspaper kiosks, we defined a city pilot where we tracked weekly numbers on cost savings and customer experience metrics. After a month, we also conducted field research to gauge both customer and employee sentiment.

For the text message solution, we ran several versions of the message copy and monitored conversion rates. The best-performing version was chosen based on these results.

While the digital solutions took time to go live, during the development phase, we conducted usability tests with actual customers on the UX wireframes and prototypes. This small sample size research validated that the flows were working well, ensuring readiness for eventual rollout

## Key Takeaways

One of the most significant learnings from the project was the power of participative research. It was a striking experience for team members, who were usually confined to their offices and cars, to go out into the field, visit customers’ homes, and observe their unique behaviors around bill payments. This firsthand exposure was eye-opening—for example, discovering customers who used their bills to start fires at home or those who overpaid to hack together a flashy deal.

Seeing the stark contrast between meticulously planned strategies and the reality of customer behavior highlighted that many of the debated details in meetings often had little impact. Instead, other overlooked gaps were far more influential on the customer experience. The fact that the cross-functional team members were directly involved in the field research, rather than just reading a report, created a deep, personal understanding and commitment. This experience turned them into internal champions, driven to make meaningful changes and move the project forward.

` 
};
