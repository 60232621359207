import { ICaseStudyCalloutItem } from "../../types";

const CaseStudyCalloutItem = ({ label, sections }: ICaseStudyCalloutItem) => {
    return (
        <div className='flex flex-col space-y-2 text-sm'>
            <p className='text-slate-500 uppercase'>{label}</p>
            {
                sections.map((section, index) => (
                    <div key={index} className="font-extra-condensed ">
                        <p className="font-medium ">{section?.title}</p>
                        <p>{
                            section?.values?.join(' • ')
                        }
                        </p>
                    </div>
                ))
            }

        </div>
    );
}

export const CaseStudyCallout = ({ caseStudyCalloutItems }: {
    caseStudyCalloutItems: ICaseStudyCalloutItem[]
}) => {
    return (
        <div className='flex flex-wrap md:flex-col  gap-4 md:gap-0 pt-4 md:pt-0 pb-8 md:pb-0 border-t border-b border-slate-300 md:border-0 space-y-0 md:space-y-4'>
            {caseStudyCalloutItems.map((item, index) => <CaseStudyCalloutItem key={index} label={item.label} sections={item.sections} />)}
        </div>
    );
}