
import { ICaseStudy } from "../../../types";


export const scotiaOlderAdults: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/scotiamobileadoption/hero.jpg`,
    headline: 'Mobile App Adoption for Older Adults',
subheadline: 'Redesigned the mobile banking journey to increase digital adoption among older adults by addressing key barriers and introducing tailored digital touchpoints.',
overview: 'Led a research-driven redesign of the mobile banking journey for older adults, resulting in a 60% increase in digital adoption among customers aged 55 and older. Collaborated with Data Analytics and Behavioral Economics teams to identify barriers, developed user archetypes, and introduced new digital features such as a “Seniors Resource Center” and personalized branch support to improve adoption and satisfaction.',
goals: [
    {
        title: 'Digital Adoption',
        values: [
            'Increase digital adoption among older adults through tailored digital experiences',
            'Create a digital journey that aligns with the unique needs of older adults, such as fraud awareness and in-branch support'
        ]
    },
    {
        title: 'Customer Experience',
        values: [
            'Provide a seamless, age-appropriate digital journey with dedicated support for older adults',
            'Empower older customers with tools like the “Seniors Resource Center” to build trust and digital confidence'
        ]
    },
    {
        title: 'Customer Engagement',
        values: [
            'Enhance engagement by developing personas and archetypes that reflect different age-specific needs',
            'Introduce digital touchpoints designed to address specific behavioral drivers and barriers'
        ]
    }
],
outcomes: {
    title: 'Outcomes',
    values: [
        '60% increase in digital adoption among customers aged 55 and older',
        'Improved customer satisfaction through tailored digital and in-branch support',
        'Better alignment on digital engagement strategies through collaborative research and workshops with cross-functional teams'
    ]
},
callouts: [
    {
        label: 'My role',
        sections: [
            {
                title: 'Senior Service Designer',
                values: [
                    'Cross-channel transaction data analysis',
                    'Behavioral hypothesis generation',
                    'Field research planning and execution',
                    'Persona and archetype development',
                    'Experience mapping',
                    'Workshop facilitation',
                    'Service blueprinting',
                    'Collaborative development of digital touchpoints'
                ]
            }
        ]
    },
    {
        label: 'Methods',
        sections: [
            {
                title: 'Research Methods',
                values: [
                    'Cross-channel transaction data analysis',
                    'Field research (branch visits, staff shadowing, and interviews)',
                    'Collaborative workshops with Behavioral Economics and Data Science teams',
                    'User interviews and qualitative research'
                ]
            },
            {
                title: 'Design Workshops',
                values: [
                    'Current-state journey mapping',
                    'Persona and archetype development workshops',
                    'Co-creation workshops with diverse age groups',
                    'Future-state blueprinting workshops'
                ]
            },
            {
                title: 'Deliverables',
                values: [
                    'Behavioral Hypotheses and Customer Segment Profiles',
                    'Field Research Plan and Insights Report',
                    'User Archetypes',
                    'Co-designed future-state touchpoints',
                    'Future-State Service Blueprints'
                ]
            }
        ]
    },
    {
        label: 'Team',
        sections: [
            {
                title: 'Digital Team',
                values: [
                    'Data Science',
                    'Behavioral Economics',
                    'Service Designers',
                    'Product Owners',
                    'UX Designers'
                ]
            },
            {
                title: 'Stakeholders',
                values: [
                    'Digital Adoption Team',
                    'Retail',
                    'Call Center',
                    'Branch Staff',
                ]
            }
        ]
    }
],
    processMD:`
## Design Process

1. Analyzed cross-channel transaction data (web, app, branches, call centers) to identify target segments, focusing on digitally activated but not engaged users.
2. Collaborated with Behavioral Economics to generate behavioral hypotheses (e.g., adoption barriers in older or rural populations) and to set up propensity models for digital adoption behavior.
3. Collaborated with Data Science to analayze multidimensional customer segment- and digital propensity data (digital engagement, demographics, location) to validate and refine team assumptions about digital adoption patterns.
4. Planned, conducted, and analyzed field research (branch visits, customer interviews, staff interviews, and -shadowing) to gain understanding of adoption barriers, in-branch-, and digital user behaviors. Uncovered key insights into how branch proximity, fraud risk, and digital education influences digital engagement.
5. Facilitated co-creation workshops with diverse age groups to prototype future digital banking experiences across three modes—immersion, on-the-go, and ambient awareness—resulting in user archetypes that defined age-specific needs like fraud alerts for older adults and impulse-control for young adults.
6. Facilitated ideation and future-state blueprinting workshops to refine age-specific digital banking services, prioritizing older adults first and their unique needs (e.g. enhanced fraud awareness).
7. Supported Agile product teams in prototyping, testing, and implementing proposed digital touchpoints, ensuring alignment with user archetypes and age-specific needs identified during workshops.

` 
};
