
import { ICaseStudy } from "../../../types";


export const scotiaCreditCards: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/scotiacreditcards/hero.png`,
    headline: 'Credit Card Customer Journeys and Microservices Alignment',
subheadline: 'Redesigned credit card customer journeys by developing reusable Service Patterns and aligning them with a microservices architecture across 12 agile teams.',
overview: 'Led a modular redesign of credit card-related customer journeys, creating Service Patterns that enabled the reuse of consistent journey segments. Mapped these patterns to a new microservices architecture across 12 agile teams, ensuring standardized processes and seamless integration of accessibility, regulatory, and UX requirements. Collaborated closely with UX and Design System teams to implement and test these patterns, supporting a more efficient and consistent product development process.',
goals: [
    {
        title: 'Scalability',
        values: [
            'Develop reusable Service Patterns to standardize journey segments across multiple credit card products',
            'Ensure scalability of solutions by aligning journey segments with a modular microservices architecture'
        ]
    },
    {
        title: 'Operational Efficiency',
        values: [
            'Reduce development time by providing pre-defined Service Patterns for common customer journeys',
            'Streamline the design and implementation process through standardized journey segments and internal processes'
        ]
    },
    {
        title: 'Consistency',
        values: [
            'Ensure consistent user experiences across different products and journeys by applying standardized Service Patterns',
            'Incorporate accessibility, regulatory, and UX requirements into reusable patterns to ensure compliance and user satisfaction'
        ]
    }
],
outcomes: {
    title: 'Outcomes',
    values: [
        'Created a Service Pattern Library for seamless reuse across different credit card customer journeys',
        'Improved alignment and efficiency across 12 agile teams through standardized journey segments',
        'Enhanced design system standards to support the consistent application of Service Patterns across products'
    ]
},
callouts: [
    {
        label: 'My role',
        sections: [
            {
                title: 'Senior Service Designer',
                values: [
                    'Service blueprinting workshops',
                    'Process mapping',
                    'Journey mapping',
                    'Service Pattern development',
                    'Collaboration with Design Strategy and UX teams',
                    'Integration with Design System standards',
                    'Workshop facilitation across agile teams'
                ]
            }
        ]
    },
    {
        label: 'Methods',
        sections: [
            {
                title: 'Design Methods',
                values: [
                    'Service blueprinting',
                    'Process mapping workshops',
                    'User journey mapping',
                    'Development of modular Service Patterns'
                ]
            },
            {
                title: 'Design Workshops',
                values: [
                    'Current-state process mapping',
                    'Service blueprinting workshops',
                    'Service Pattern concepting and testing',
                    'Future-state service blueprinting'
                ]
            },
            {
                title: 'Deliverables',
                values: [
                    'Current-State Process Maps',
                    'Service Scenarios',
                    'User Journey Maps',
                    'Service Pattern Library',
                    'Future-State Service Blueprints',
                    'Design System Standards for Service Pattern implementation'
                ]
            }
        ]
    },
    {
        label: 'Team',
        sections: [
            {
                title: 'Project Team',
                values: [
                    'Service Designers',
                    'UX Designers',
                    'Design Strategy Team',
                    'Design System Team',
                    'Product Owners'
                ]
            },
            {
                title: 'Stakeholders',
                values: [
                    '12 Agile Product Teams',
                    'Accessibility Teams',
                    'Regulatory Compliance Teams'
                ]
            }
        ]
    }
],
    processMD:`
## Design Process

1. Facilitating Service Blueprinting workshops: mapping a new micro-services architecture to customer journeys
1. Developing modular Service Patterns (Service Blueprint segments) which could be re-used across experiences (e.g., credit card check)
1. Facilitated current-state Process Mapping and Service Blueprinting workshops across 12 agile product teams to identify reusable journey segments.
1. Worked with Design Strategy to translate  insights from user research reports into comprehensive user journey maps, ensuring the user steps and pain points are evidence-based.
1. Created reusable Service Patterns—encapsulated service blueprint modules combining user journey segments, internal processes, and embedded expertise (e.g., accessibility, regulatory, UX)—to streamline development and ensure consistency across experiences.
1. Partnered with UX teams to prototype and test Service Patterns, ensuring usability and alignment with user expectations.
1. Facilitated the adoption of Service Patterns through the design system process, collaborating with Design System team to integrate these patterns as standardized components, enabling easier adoption by product teams.
` 
};
