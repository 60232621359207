
import { ICaseStudy } from "../../../types";


export const ideateLive: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/valueprops/ux.png`,
    headline: 'Research-Driven Product Validation and Usability Optimization for Ideate Live',
    subheadline: 'Validated product-market fit and optimized usability through a multi-phase research strategy, using prototype testing, usability assessments, and product analytics.',
    overview: 'Led comprehensive research at Ideate Live, starting with assumption-based prototype demos and interviews to validate the value proposition. Conducted usability tests to refine interactive design elements, de-risking the launch. Leveraged Amplitude for end-to-end product analytics, identifying key user barriers and driving design changes based on deep qualitative insights. This approach helped shape the product and secured major trial customers such as T-Mobile and Rogers.',
    goals: [
        {
            title: 'Product Validation',
            values: [
                'Validate product-market fit through early assumption testing with potential users',
                'Refine key value propositions to ensure strong product focus for MVP development'
            ]
        },
        {
            title: 'Usability Optimization',
            values: [
                'Improve the interactive design by gathering real user feedback through usability tests',
                'Identify and address potential usability issues to enhance user experience and reduce launch risks'
            ]
        },
        {
            title: 'Customer Acquisition',
            values: [
                'Secure trial customers by ensuring the product’s key value propositions and usability meet expectations',
                'Leverage research insights to build compelling features that attract enterprise customers'
            ]
        }
    ],
    outcomes: {
        title: 'Outcomes',
        values: [
            'Validated the product’s core value proposition through early-stage testing and interviews',
            'Enhanced the interactive design through iterative usability tests, de-risking product launch',
            'Identified key barriers through product analytics and qualitative research, driving targeted design improvements',
            'Secured major trial customers, including T-Mobile and Rogers'
        ]
    },
    callouts: [
        {
            label: 'My role',
            sections: [
                {
                    title: 'Founder & Lead Researcher',
                    values: [
                        'Prototype testing and early-stage validation',
                        'Usability test planning and execution',
                        'Product analytics setup and tracking',
                        'User interviews and qualitative research',
                        'Iterative design refinement',
                        'Stakeholder presentations and alignment'
                    ]
                }
            ]
        },
        {
            label: 'Methods',
            sections: [
                {
                    title: 'Research Methods',
                    values: [
                        'Assumption-based prototype demos',
                        'Usability tests with network participants and user testing platforms',
                        'End-to-end product analytics with Amplitude',
                        'Qualitative user interviews to understand pain points'
                    ]
                },
                {
                    title: 'Design Workshops',
                    values: [
                        'Prototype demo sessions',
                        'Usability testing workshops',
                        'Ideation and concept refinement workshops'
                    ]
                },
                {
                    title: 'Deliverables',
                    values: [
                        'Prototype test reports',
                        'Usability findings and recommendations',
                        'Product analytics insights',
                        'Refined MVP feature set',
                        'Stakeholder presentation and outcome report'
                    ]
                }
            ]
        },
        {
            label: 'Team',
            sections: [
                {
                    title: 'Product Team',
                    values: [
                        'Founder and Lead Designer',
                        'Chief Technical Officer',
                    ]
                },
                {
                    title: 'Stakeholders',
                    values: [
                        'Potential Early Adopter Customers'
                    ]
                }
            ]
        }
    ],
    processMD:`
# Design Process for Ideate Live

<figure>
  <video src="${process.env.PUBLIC_URL + `/casestudies/ideatelive/video.mp4`}" controls alt="IdeateLive demo video">
  </video>
  <figcaption>IdeateLive demo walkthrough.</figcaption>
</figure>

## 1. Initial Validation

We began by validating the need for the product. We developed a set of assumptions about our core value propositions and conducted prototype demos and interviews with potential customers from industry conferences and our network. This early-stage research allowed us to validate which unique value propositions resonated most with potential users, giving us a clear focus for developing our MVP.

## 2. Usability Testing

After the initial validation, we shifted to usability testing to refine the interactive design elements. These tests were conducted with participants from both our network and external user testing platforms. The focus was on testing the new interaction models designed for group workshops. We gathered feedback on ease-of-use and intuitiveness, iterating on the prototypes to improve the experience. This phase helped de-risk the launch by addressing usability issues early on, ensuring that the product was intuitive and effective.

## 3. Product Analytics and Iterative Improvement

Once the product was live, we used Amplitude to track the complete customer journey, focusing on how well users navigated the platform and identified key pain points. Unlike Google Analytics, which is page-based, Amplitude allowed us to track behavior across various user journeys, defining “happy” and “unhappy” paths. When we identified critical barriers to value, we used qualitative interviews to dig deeper into these issues. For example, we found that many users struggled with the event creation step, particularly matching templates to their meeting intent.

## 4. Design Refinement Based on Insights

Based on the insights from product analytics and interviews, we redesigned the event creation experience to focus on the host’s intent rather than pre-set templates. We introduced a new approach that allowed users to enter their meeting intent, and the system automatically generated a workshop or meeting plan using generative AI. This change not only improved the overall user experience but also unlocked new customer segments, helping us secure our first trial customers, including T-Mobile and Rogers.

## 5. Outcome and Impact

Through a combination of early validation, usability testing, and ongoing product analytics, we were able to continuously refine the product and validate its value proposition. The iterative approach ensured a solid user experience, reduced launch risks, and attracted major trial customers. This process showcased the power of combining qualitative and quantitative research to build a product that met user needs and expectations.

`


};
