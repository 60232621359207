import { Route, Routes, useParams } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { NavBar } from './components/NavBar';
import CategoryPage from './pages/CategoryPage';
import CaseStudyPage from './pages/CaseStudyPage';

// img example: <img src={logo} className="App-logo" alt="logo" />
// css: //@import '@radix-ui/themes/styles.css';

const CategoryRoute = () => {
  const { category } = useParams();
  return <CategoryPage category={category} />;
};

const CaseStudyRoute = () => {
  const { category, casestudy } = useParams();
  return <CaseStudyPage
    caseStudyId={casestudy}
    parentId={category}
  />;
};

function App() {
  return (
    <div className=' bg-gradient-to-t from-indigo-100 to-slate-50 min-h-screen pb-20 flex flex-col items-center '>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/case-studies/:category" element={<CategoryRoute />} />
          <Route path="/case-studies/:category/:casestudy" element={<CaseStudyRoute />} />
        </Routes>
    </div>
  );
}

export default App;
