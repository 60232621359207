import { Link } from 'react-router-dom';


export const NavBar = () => {
  return (
    <nav className='flex flex-col items-center p-2 md:p-4 w-full bg-slate-950'>
      <Link to="/">
        <h1 className={`text-lg md:text-2xl  text-white uppercase `}>Francis Szakacs</h1>
      </Link>
    </nav>
  )
}