import { Link } from "react-router-dom"
import { IValueProp } from "../types"
import { valueProps } from "./caseStudies/categories"





const ValueProp = ({ headline, subheadline, ctaLabel, img, id }: IValueProp) => {
    return (
        <Link to={`/case-studies/${id}`}>
        <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-8 space-y-4 md:space-y-0 max-w-screen-lg'>
            <div className='grow md:w-1/2'>
                <img src={img} alt="Value Prop Image" className='rounded-md aspect-video object-cover' />
            </div>
            <div className='grow md:w-1/2 flex flex-col space-y-2 md:space-y-4 text-left'>
                <p className='text-xl md:text-2xl font-bold'>{headline}</p>
                <p className='text-base'>{subheadline}</p>

                <div className="py-2 text-accent-dark text-sm inline-flex items-center">

                    {ctaLabel}
                    <span className="ml-2">→</span>

                </div>
            </div>
        </div>
        </Link>
    )
}

export const ValueProps = () => {
    return (
        <>
            {
                valueProps.map(vp => <ValueProp headline={vp.headline} subheadline={vp.subheadline} ctaLabel={vp.ctaLabel} img={vp.img} id={vp.id} />)
            }
        </>

    )
}