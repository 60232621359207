import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const withPasswordProtection = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const correctPassword = 'Francis'; // Replace with your desired password

  const PasswordProtectedComponent: React.FC<P> = (props) => {
    const [password, setPassword] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
      const savedAuthorization = Cookies.get('isAuthorized');
      if (savedAuthorization === 'true') {
        setIsAuthorized(true);
      }
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (password?.toLowerCase() === correctPassword?.toLowerCase()) {
        setIsAuthorized(true);
        Cookies.set('isAuthorized', 'true', { expires: 7 }); // Expires in 7 days
      } else {
        alert('Incorrect password, please try again.');
      }
    };

    if (isAuthorized) {
      return <WrappedComponent {...props} />;
    }

    return (
      <div className='flex flex-col items-center justify-center grow '>
        <div className='max-w-md w-full md:bg-white p-4 md:p-8 md:rounded-md md:shadow-xl'>
          <div className='flex flex-col items-center text-center space-y-4'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
            </svg>
            <form onSubmit={handleSubmit} className='flex items-center'>
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='px-4 py-2 md:px-8 md:py-4  md:text-lg border rounded-l-full border-r-0 mb-0'
              placeholder='Password'
            />
            <button type='submit' className='primary-button rounded-r-md !rounded-l-none border-l-0'>
              Submit
            </button>
          </form>
          </div>
        </div>
      </div>
    );
  };

  return PasswordProtectedComponent;
};

export default withPasswordProtection;