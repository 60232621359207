import { useState } from "react";

const socialProofData: ISocialProofCardData[] =
    [
        {
            // Animatr.ai
            number: "+30%",
            unit: "App Activation rate",
            logo: process.env.PUBLIC_URL + "/logos/animatrai.png",
            quote: "Francis’ mix of design, coding, and AI skills helped us achieve our next funding milestone."
        },
        {
            number: "+10%",
            unit: "Digital Payment Adoption",
            logo: process.env.PUBLIC_URL + "/logos/tmobile.png",
            quote: "The way we design our services is an increasingly important source of competitive advantage."
        },
        {
            number: "$26B",
            unit: "Shaw Merger value",
            logo: process.env.PUBLIC_URL + "/logos/rogers.png",
            quote: "Francis's Director-plus journey mapping sessions sped up alignment and ensured a smooth transition."
        },
        {
            number: "+60%",
            unit: "Seniors Digital Adoption",
            logo: process.env.PUBLIC_URL + "/logos/scotiabank.png",
            quote: "Francis' ability to turn complex problems into actionable solutions is truly amazing."
        },
        {
            number: "+20%",
            unit: "Payment Cost Reduction",
            logo: process.env.PUBLIC_URL + "/logos/tmobile.png",
            quote: "The way we design our services is an increasingly important source of competitive advantage."
        },
        {
            number: "$30M",
            unit: "Call Deflection Savings",
            logo: process.env.PUBLIC_URL + "/logos/rogers.png",
            quote: "Francis's guidance at Rogers helped me grow as a service designer and improve partner experiences."
        },
        {
            number: "+22%",
            unit: "Global CSAT increase",
            logo: process.env.PUBLIC_URL + "/logos/tmobile.png",
            quote: "Francis' creativity, problem-solving, and effective use of service design tools made him a pleasure to work with."
        },
    ]

interface ISocialProofCardData {
    number: string;
    unit: string;
    logo?: string;
    quote?: string;
}

const SocialProofCard = ({ logo, number, unit, quote }: ISocialProofCardData) => {
    return (
        <div className="flex flex-col items-center cursor-pointer w-56 h-72 bg-white shadow-lg rounded-lg m-4 p-4 text-center transition-transform transform hover:scale-105 duration-300 max-w-sm mx-auto">
            <div className='max-h-12 mb-2 p-4'>
                <img src={logo} alt="Company Logo" className="max-h-4" />
            </div>
            <div className=" mb-2">
                <div className="text-5xl bg-gradient-to-r from-indigo-600 to-rose-600 bg-clip-text text-transparent">{number}</div>
            </div>
            <div className="text-md font-condensed font-semibold text-primary uppercase mb-4">{unit}</div>
            <p className="text-primary-light font-extra-condensed mb-6">“{quote}”</p>
            {/* <a href="#" className="text-blue-500 font-medium hover:underline">
          Learn more →
        </a> */}
        </div>
    )
}

interface HorizontalScrollerProps {
    children: React.ReactNode[];
}


const HorizontalScroller: React.FC<HorizontalScrollerProps> = ({ children }) => {
    const [isPaused, setIsPaused] = useState(false);

    const handleMouseDown = () => setIsPaused(true);
    const handleMouseUp = () => setIsPaused(false);
    const handleMouseEnter = () => setIsPaused(true);
    const handleMouseLeave = () => setIsPaused(false);

    return (
        <div className="relative overflow-x-auto whitespace-nowrap w-screen"
            style={{
                scrollbarWidth: 'none',   // Firefox
                msOverflowStyle: 'none',  // IE and Edge
                scrollSnapType: isPaused ? 'x mandatory' : "inherit" // 'x mandatory'  // Enables scroll snapping horizontally
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className={`flex space-x-6 min-w-max whitespace-normal animate-scroll`}
                style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
            >
                {children.map((child, index) => (
                    <div key={index} 
                        className="scroller-item pl-4"
                        style={{
                            scrollSnapAlign: 'start'  // Aligns each card to the start when snapping
                        }}
                        >
                        {child}
                    </div>
                ))}
                {children.map((child, index) => (
                    <div key={`duplicate-${index}`} 
                        className="scroller-item pl-4"
                        style={{
                            scrollSnapAlign: 'start'  // Aligns each card to the start when snapping
                        }}
                        >
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const SocialProofs = () => {
    return (
        <HorizontalScroller>
            {socialProofData.map((item, index) => (
                <SocialProofCard
                    key={index}
                    logo={item.logo}
                    number={item.number}
                    unit={item.unit}
                    quote={item.quote}
                />
            ))}
        </HorizontalScroller>

    )
}