import React from 'react';
import { Link } from 'react-router-dom';
import { SocialProofs } from '../components/SocialProofCard';
import { Logos } from '../components/Logos';
import { SkillCards } from '../components/SkillCards';
import { BlockHeader } from '../components/BlockHeader';
import { ValueProps } from '../components/ValueProp';

const CTALabel = "Connect on LinkedIn"
const CTASecondaryLabel = "Book a call"

const onCTAClick = () => {
    window.open('https://www.linkedin.com/in/francisszakacs/', '_blank');
}

const onCTASecondaryClick = () => {
    window.open('https://calendly.com/szakacsszilard/personal-30', '_blank');
}

const HomePage = () => {
    return (
        <>
            <header className='flex flex-col items-center  md:m-16 text-center space-y-4 max-w-full md:max-w-prose p-4 mt-4 mb-8 md:mb-24'>
                <div className='mt-4 md:mt-8'>
                    <h1 className='text-3xl md:text-4xl font-semibold text-slate-950 mb-2 md:mb-6'>The design technologist for complex organizations</h1>
                    <p className='text-base md:text-lg text-slate-800'>10 years of leading Service- and UX Design in finance, telecom, and tech.</p>
                </div>
                <div className='flex  space-x-2 md:space-x-4 pt-2 pb-2 md:py-8'>
                    <button className='primary-button flex-1 whitespace-nowrap' onClick={onCTAClick}>{CTALabel}</button>
                    <button className='secondary-button flex-1 whitespace-nowrap' onClick={onCTASecondaryClick}>{CTASecondaryLabel}</button>
                </div>

                <SocialProofs />
                <div className='pt-4 md:pt-8'>
                    <Logos />
                </div>

            </header>

            <div className="main-container">
                <BlockHeader
                    // headline="For tech-forward teams who put customers first"
                    headline='Bridging business, digital, and user needs'
                />
                <SkillCards />
            </div>

            <div className="main-container">
                <BlockHeader
                    headline='Why teams work with Francis'
                    subheadline='Service design to untangle complexity, digital expertise to build robust solutions, and AI experience to innovate with confidence.'
                />
                <ValueProps />
            </div>

            <div className="main-container text-center !space-y-2 !md:space-y-4 ">
                <BlockHeader
                    headline="Solve complex problems with proven design leadership"
                />
                <div className='flex space-x-0 w-full max-w-prose'>

                    <div>
                        <img src={process.env.PUBLIC_URL + "/illustrations/rocket.png"} alt="Rocket" className='rounded-md rotate-45' />

                    </div>

                    <div>
                        <img src={process.env.PUBLIC_URL + "/illustrations/human.png"} alt="Human" className='rounded-md' />

                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + "/illustrations/magic.png"} alt="Magic" className='rounded-md -rotate-12' />
                    </div>

                </div>
                <div className='text-sm md:text-base'>
                    <p className='font-semibold mb-2'>
                        <span className=' whitespace-nowrap inline-block'>Blend strategy, design, and tech </span>{" "}
                        <span className=' whitespace-nowrap inline-block'>for seamless experiences.</span>
                    </p>
                    <p>Get results-driven design with Francis.</p>
                </div>
            </div>

            <div className='main-container text-center'>
                <div>
                    <div className=" text-4xl md:text-5xl bg-gradient-to-r from-indigo-600 to-rose-600 bg-clip-text text-transparent p-1">Ready to turn strategy into action?</div>
                    <div className='flex justify-center space-x-5 pt-10 md:pt-16 md:mb-24'>
                        <button className='primary-button' onClick={onCTAClick}>{CTALabel}</button>
                        <button className='secondary-button' onClick={onCTASecondaryClick}>{CTASecondaryLabel}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;