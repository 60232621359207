import React, { ReactElement, useEffect } from 'react';
import { CaseStudyCallout } from '../components/caseStudies/CaseStudyCallout';
import { ICaseStudy, ICategoryItem, ITitledValues, IValueProp } from '../types';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw"
import { Link } from 'react-router-dom';
import { caseStudies, valueProps } from '../components/caseStudies/categories';

const TitledValues = ({ values }: { values: ITitledValues[] }) => {
  return (
    <div className='flex flex-col space-y-2'>
      {
        values.map((value, index) => (
          <div key={index} className='flex flex-col space-y-2'>
            <p className='font-medium'>{value.title}</p>
            <ul className='ml-4'>{value.values.map((val, index) => <li key={index} className='pl-2 list-outside list-disc'>{val}</li>)
            }</ul>
          </div>
        ))
      }
    </div>

  );
}

interface ICaseStudyPageProps {
  caseStudyId: string | undefined
  parentId: string | undefined
}

const CaseStudyPage = ({caseStudyId, parentId }: ICaseStudyPageProps) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const caseStudyData: ICaseStudy | undefined = caseStudies.find((cs: ICategoryItem) => cs.id === caseStudyId)?.caseStudy;
  const categoryPageData: IValueProp | undefined = valueProps.find((vp: IValueProp) => vp.id === parentId);
  
  return (
    !caseStudyData ? <div>Invalid case study or category</div> : <>
      <div className='relative w-full h-48 md:h-96 mb-10 md:mb-20'>
        {caseStudyData?.heroImg && <img src={caseStudyData?.heroImg} alt="Hero Image" className='absolute top-0 left-0 w-full h-full object-cover' />}
      </div>

      <div className='flex flex-col justify-stretch space-y-4 px-6 md:px-12 '>
        {
          // headline
        }
        <div className="mb-4 md:mb-10 max-w-prose">
        <Link to={`/case-studies/${parentId}`}>
        <button className=' text-accent-dark hover:underline pb-2 md:pb-4 text-left'>{`← Back to ${categoryPageData ? categoryPageData.headline : "all"} case studies`}</button>
        </Link>
       
          <p className='font-bold text-2xl md:text-4xl mb-4'>{caseStudyData?.headline}</p>
          <p className='text-lg md:text-2xl'>{caseStudyData?.subheadline}</p>
        </div>
        {
          // intro
        }
        <div className='flex flex-col justify-center md:flex-row w-full space-y-10 md:space-y-0 space-x-0 md:space-x-28'>
          <div className='flex-1 space-y-8 max-w-prose '>
            <div>
              <h2 className=' text-lg md:text-xl mb-4 uppercase text-slate-600'>Overview</h2>
              {/* <p className=' md:text-lg '>{caseStudyData?.overview}</p> */}
              <ReactMarkdown className=' md:text-lg prose text-slate-950 list-disc ' children={caseStudyData?.overview} rehypePlugins={[rehypeRaw] as any} />
            </div>

            <div>
              <h2 className=' md:text-lg mb-4 uppercase text-slate-600'>Goals</h2>
              <TitledValues values={caseStudyData?.goals} />
            </div>
            <div>
              <h2 className=' md:text-lg mb-4 uppercase text-slate-600'>Outcomes</h2>
              <ul className='ml-4'>
                {
                  caseStudyData?.outcomes?.values.map((outcome, index) => (
                    <li key={index} className='list-outside pl-2 list-disc'>{outcome}</li>
                  ))

                }
              </ul>
            </div>

          </div>
          <div className='md:max-w-60'>
            <CaseStudyCallout caseStudyCalloutItems={caseStudyData?.callouts}
            />
          </div>
        </div>
        {
          // process
        }
        <article className='flex flex-col pt-8  md:pt-16 space-y-8 max-w-prose prose-sm md:prose !text-slate-950'>

       
          
          <ReactMarkdown children={caseStudyData?.processMD} rehypePlugins={[rehypeRaw] as any} />
         
        </article>


      </div>
    </>
  );
};

export default CaseStudyPage;
