
import { ICaseStudy } from "../../../types";


export const rogersTechSupport: ICaseStudy = {
    heroImg: process.env.PUBLIC_URL + `/casestudies/rogerstechsupport/hero.jpg`,
    headline: 'Tech Support Digital Transformation',
    subheadline: '$30m in savings through cross-channel data analysis and journey redesign.',
    overview: 'Led the self-serve digital adoption program using cross-channel transaction data and qualitative research to identify, prioritize, and redesign high-impact customer journeys with self-serve alternatives (e.g. roaming activation). Achieved $30M call deflection savings.',
    goals: [
        {
            title: 'Cost Reduction',
            values: [
                'Reduce call volume by migrating high-frequency support inquiries to digital channels',
                'Decrease operational costs associated with call centers and tech support staffing',
            ]
        },
        {
            title: 'Customer Experience',
            values: [
                'Streamline the support experience by providing accessible, intuitive self-serve options',
                'Empower customers with digital tools for resolving common issues independently'
            ]
        },
        {
            title: 'Digital Adoption',
            values: [
                'Increase the adoption of self-serve alternatives for tech support scenarios',
                'Encourage digital-first behavior for routine customer inquiries (e.g. roaming activation, SIM activation)',
                'Achieve higher engagement and satisfaction with digital support channels'
            ]
        }
    ],
    outcomes: {
        title: 'Outcomes',
        values: [
            '$30M saved through call deflection',
            'Better team alignment through service blueprinting workshops',
            'Improved understanding of customer needs through research and interviews'
        ]
    }
    ,
    callouts: [
        {
            label: 'My role',
            sections: [
                {
                    title: 'Lead Service Designer',
                    values: [
                        'Data analysis and research planning',
                        'Qualitative research',
                        'Workshop facilitation',
                        'Service blueprinting',
                        'Concept development',
                        'Collaboration with cross-functional teams',
                        'Agile epic creation and alignment'
                    ]
                }
            ]
        },
        {
            label: 'Methods',
            sections: [
                {
                    title: 'Research Methods',
                    values: [
                        'Cross-channel transaction data analysis',
                        'Digital ethnography',
                        'Remote user interviews',
                        'Co-creation workshops with tech support specialists'
                    ]
                },
                {
                    title: 'Design Workshops',
                    values: [
                        'Current-state journey mapping',
                        'Service blueprinting',
                        'Ideation and concepting',
                        'Target-state journey mapping'
                    ]
                },
                {
                    title: 'Deliverables',
                    values: [
                        'Program backlog',
                        'Project plan',
                        'User archetypes',
                        'User journey maps',
                        'Service blueprints',
                        'Co-designed prototypes',
                        'Prototype test plans and reports'
                    ]
                }
            ]
        },
        {
            label: 'Team',
            sections: [
                {
                    title: 'Digital Team',
                    values: [
                        'Service Designers',
                        'UX Designers',
                        'Product Owners',
                        'Engineers',
                        'Data Analysts'
                    ]
                },
                {
                    title: 'Stakeholders',
                    values: [
                        'Tech Support Operations',
                        'Customer Experience Team',
                        'Digital Support Team',
                        'SEO and Personalization Teams',
                        'Support Innovation Team'
                    ]
                }
            ]
        }
    ],
    processMD:`
## Design Process

1. Analyzed cross-channel support transaction data, incuding call-centres, tech support, and web (e.g. roaming activation, SIM activation, device upgrade) to identify and prioritize user intents with a self-serve alternative.
1. Planned, conducted, and analyzed qualitative research (digital ethnography, remote user interviews) to understand behavioural drivers for channel choices and barriers for adopting digital ones.
1. Facilitated co-creation workshops with frontline tech support specialists, leveraging their firsthand insights to map out and ideate an optimal digital support experience. Identified key opportunities for channel migration by aligning specialist expertise with user needs.
1. Facilitated comprehensive service blueprinting workshops, mapping out the current state of omni-channel customer journeys. Engaged cross-functional teams to identify and document key barriers and drivers for channel choice, ensuring a unified understanding across the organization. 
1. Led ideation and concepting workshops to explore identified opportunities, developing low-fidelity concepts into detailed storyboards, and future-state Service Blueprints. 
1. Collaborated with the UX design team to translate these concepts into functional prototypes. Conducted usability tests with target customer groups to validate our assumptions and refine the user experience.
1. Leveraged service design to orchestrate a cross-functional Agile team, collaborating with Product Owners, UX designers, Engineers, and teams specializing in data analysis, SEO, personalization, and support innovation.
1. Collaborated with the Product Owner to convert the target-state service blueprint into Agile epics. Facilitated workshops to align stakeholders involved in the implementation, covering change management efforts and governance aspects of the target-state solutions.

` 
};
