const skillCardData = [
    { title: "Service Design", icon: process.env.PUBLIC_URL + "/icons/journeymap.png" },
    { title: "UX Research", icon: process.env.PUBLIC_URL + "/icons/ecosystemmap.png" },
    { title: "UX Design", icon: process.env.PUBLIC_URL + "/icons/uxdesign.png" },
    { title: "Workshop Facilitation", icon: process.env.PUBLIC_URL + "/icons/workshops.png" },
    { title: "Generative AI", icon: process.env.PUBLIC_URL + "/icons/genai.png" },
    { title: "Web Development", icon: process.env.PUBLIC_URL + "/icons/frontend.png" },
  ]
  
  const SkillCard = ({ title, icon }: { title: string, icon: string }) => {
    return (
      <div className='flex flex-col space-y-2 md:space-y-4 items-center w-32 md:w-40 '>
        <div className="max-h-16 mb-0 md:mb-0">
          <img src={icon} alt="Skill Icon" className='w-12 md:w-16' />
        </div>
  
        <p className='text-center text-base font-medium'>{title}</p>
  
      </div>
  
  
  
    )
  }

  export const SkillCards = () => {
    return (
        <div className='flex flex-wrap justify-center max-w-prose gap-8 md:gap-10'>
        {
          skillCardData.map(skill => <SkillCard title={skill.title} icon={skill.icon} />)
        }
      </div>
    )
  }